#ibm__snack_bar_message_id {
  display: flex;
  align-items: center;
  justify-content: center;
}
#ibm__snack_bar_message_id div {
  text-align: center;
}

.ibm__snack_container {
  width: 100%;
  max-width: none !important;
}

.ibm__snackbar-icon {
  margin-right: 10px;
}

.ibm__snack_container.ibm__snack-error {
  background-color: #9d1a36;
}

.ibm__snack_container.ibm__snack-success {
  background-color: #00667f;
}
