.list-checkbox-disabled > .MuiSvgIcon-root {
  fill: #bdbdbd;
}
.rc-menu {
  z-index: 1300;
}

.menu-list-item-icon-disabled {
  fill: #bdbdbd !important;
}

.menu-list-item-disabled {
  opacity: 0.3;
  pointer-events: "none";
}
.szh-menu__item--type-checkbox.szh-menu__item--checked::before {
  content: none;
}
