@import url("https://fonts.googleapis.com/css?family=Noto+Serif|Source+Sans+Pro");
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
  min-height: 300px;
  width: 520px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  min-height: 100vh;
  background-color: #f7f7f7;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1 1 100%;
  min-height: 100vh;
  font-size: 16px;

  /* Fix for react bearutiful DND issue - "Window scrolling is currently not supported for fixed lists" */
  /* https://github.com/atlassian/react-beautiful-dnd/issues/1275 */
  overflow-y: auto;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
}

h1 {
  font-family: "Noto Serif", sans-serif;
  font-size: "2.6rem";
  color: #094183;
}

.ibm__router-container-link {
  text-decoration: none;
  color: inherit;
}

.ibm__icon_container {
  padding-right: 5px;
  display: inline-block !important;
}

.ibm__icon_container i {
  display: block;
  font-size: 0.8rem !important;
}

.ibm__text_next_to_icon {
  font-size: 0.8rem !important;
  display: inline !important;
}

/* In progress spinner */

.changes-in-progess-overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: block;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 1301;
  /* Specify a stack order in case you're using a different order for other elements */
  overflow-x: hidden;
  overflow-y: hidden;
}

.changes-in-progess-overlay .overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 5rem;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 5px solid #4d4d4d;
  /* Light grey */
  border-top: 5px solid #bfbfbf;
  /* Blue */
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.print-only {
  display: none !important;
}
@media print {
  .print-only {
    display: block !important;
  }
  .do-not-print {
    display: none !important;
  }
  button.do-not-print {
    display: none !important;
  }
  header.do-not-print {
    display: none !important;
  }
  .MuiTooltip-popper {
    display: none !important;
  }
  .print-on-single-page {
    break-inside: avoid !important;
  }
  .app-subheading h1 {
    margin-bottom: 0px !important;
  }
  .app-subheading h3 {
    margin-bottom: 0px !important;
  }
  .printable-collapse {
    height: inherit !important;
    visibility: initial !important;
  }
  .printable-collapse .MuiCollapse-container.MuiCollapse-hidden {
    height: inherit !important;
    visibility: initial !important;
  }
  .printable-collapse .MuiCollapse-container {
    break-inside: avoid !important;
  }
  .printable-collapse .MuiCollapse-wrapper {
    break-inside: avoid !important;
  }

  #root {
    /* Revert the fix for react bearutiful DND issue - causes print issues */
    overflow-y: unset;
    height: unset;
    width: unset;
    position: unset;
    top: unset;
  }
}
