.ibm__content-container {
  display: flex;
  padding: 1rem;
  flex: 1 1 100%;
  width: calc(100% - 2rem);
}

.ibm__centered-container {
  padding-top: 5rem;
  max-width: 500px;
}

.ibm__max-width-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  max-width: 1024px;
  min-width: 584px;
  z-index: 0;
  margin: 12px auto 0 auto;
}

.uom-blue-background {
  color: white;
  background-color: #094183;
}

.logo {
  height: 7rem;
}

.steps {
  width: 300px;
  height: 300px;
}

.ibm__over-drop-target {
  border: dashed 1px rgba(153, 162, 170, 0.51) !important;
  background-color: rgba(172, 172, 172, 0.24) !important;
}

.ibm__green-check {
  color: #2dc03d;
  padding-right: 1rem;
  vertical-align: middle;
}

.ibm__grey-cross {
  color: #cacaca;
  padding-right: 1rem;
  vertical-align: middle;
}

.ibm__dark-grey-icon {
  color: #4a4a4a;
  padding-right: 1rem;
  vertical-align: middle;
}

/** FLEX GRID STYLES **/

.ibm__flex-column {
  display: flex;
  flex-direction: column;
}

.ibm__flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ibm__align-start {
  align-items: flex-start;
}

.ibm__align-center {
  align-items: center;
}

.ibm__align-end {
  align-items: flex-end;
}

.ibm__justify-start {
  justify-content: flex-start;
}

.ibm__justify-center {
  justify-content: center;
}

.ibm__justify-end {
  justify-content: flex-end;
}

.ibm__justify-between {
  justify-content: space-between;
}

.ibm__justify-around {
  justify-content: space-around;
}

.ibm__align-self-end {
  align-self: flex-end;
}

.ibm__flex-100 {
  display: flex;
  flex: 1 1 100%;
}

/** STANDARD PADDING AND MARGIN STYLES **/
.ibm__no-padding {
  padding: 0;
}

.ibm__padding-1rem {
  padding: 1rem;
}

.ibm__margin-top {
  margin: 1rem 0;
}

.ibm__margin-side {
  margin: 0 2rem;
}

.ibm__margin-top-extended {
  margin-top: 3rem;
}

.ibm__margin-bottom {
  margin: 0 0 1rem 0;
}

.ibm__margin-bottom-extended {
  margin-bottom: 3rem;
}

.ibm__padding-side {
  padding: 0 3rem;
}

.ibm__padding-right {
  padding-right: 1rem;
}

.ibm__padding-left {
  padding-left: 1rem;
}

.ibm__padding-top {
  padding-top: 1rem;
}

.ibm__issue_text_padding {
  padding-left: 1.75rem;
}

/** BORDER STYLES **/

.ibm__rounded-border {
  border-radius: 5px;
}

/** TEXT STYLES **/

.ibm__text-xl-sans {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 2.25rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  margin: 3rem 0;
}

.ibm__text-xl-sans-light {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  margin: 3rem 0;
}

.ibm__text-lg-serif {
  font-family: "Noto Serif", serif;
  font-size: 1.375rem;
}

.ibm__text-lg-sans {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.375rem;
  font-weight: 600;
}

.ibm__text-lg-sans-light {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.375rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
  margin-bottom: 3rem;
}

.ibm__flex-space-between-container {
  display: flex;
  justify-content: space-between;
}
.ibm__goal-header-burger-icon-button {
  margin: -5px !important;
}
.ibm__goal-header-burger-icon {
  color: white;
}

.ibm__subject-header-burger-icon {
  color: #666666;
}

.ibm__text-md-sans {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.25rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.ibm__text-sm-sans {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.6px;
}

.ibm__text-xs-sans {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.6px;
}

.ibm__text-align-center {
  text-align: center;
}

.ibm__text-blue {
  color: #094183;
}
.ibm__text-grey {
  color: #4a4a4a;
}

.ibm__text-light-grey {
  color: #99a2aa;
}
.ibm__text-white {
  color: #fff;
}

.ibm__text-bold {
  font-weight: bold;
}

.ibm__line-height-extended {
  line-height: 1.5rem;
}
